import {enableProdMode, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {RouteReuseStrategy, provideRouter} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {environment} from '@env-team/environment';
import {provideStore} from "@ngrx/store";
import {teamLoginStore} from "@team/pages/login/store/team-login.reducer";
import {provideEffects} from "@ngrx/effects";
import {TeamLoginEffects} from "@team/pages/login/store/team-login.effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {IonicStorageModule} from "@ionic/storage-angular";
import {Drivers} from '@ionic/storage';
import {appStore} from "@core/store/app.reducer";
import {HttpClientModule, HttpClient} from "@angular/common/http";
import {DefaultDataServiceConfig, EntityDataModule, EntityMetadataMap, provideEntityData} from "@ngrx/data";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TeamInfosApiEffects} from "@team/pages/infos/store/infos-api.effects";
import {teamInfosStore} from "@team/pages/infos/store/infos.reducers";
import {teamKnowledgeStore} from "@team/pages/knowledge/store/knowledge.reducers";
import {TeamKnowledgeApiEffects} from "@team/pages/knowledge/store/knowledge-api.effects";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {attachmentsStore} from "@shared/components/attachments/store/attachments.reducers";
import {AttachmentsApiEffects} from "@shared/components/attachments/store/attachments-api.effects";
import {sideBarStore} from "@team/components/side-bar/store/side-bar.reducers";
import {SideBarApiEffects} from "@team/components/side-bar/store/side-bar-api.effects";
import {TeamDocumentsApiEffects} from "@team/pages/documents/store/documents-api.effects";
import {teamDocumentsStore} from "@team/pages/documents/store/documents.reducers";
import * as routes from "@team/routes";
import {TeamAppComponent} from "@team/team-app.component";
import {teamIssuesStore} from "@team/pages/issues/store/team-issues.reducers";
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {TeamIssuesApiEffects} from "@team/pages/issues/store/team-issues-api.effects";


if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);

const entityMetadataMap: EntityMetadataMap = {
  Chat: {
    selectId: chat => chat.chatByFormDefinitionId
  }
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiUrl.one
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(TeamAppComponent, {
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    provideStore({
      app: appStore,
      login: teamLoginStore,
      teamInfos: teamInfosStore,
      teamKnowledge: teamKnowledgeStore,
      teamDocuments: teamDocumentsStore,
      issues: teamIssuesStore,
      attachments: attachmentsStore,
      sideBar: sideBarStore
    }),
    provideEffects([
      TeamLoginEffects,
      TeamInfosApiEffects,
      TeamDocumentsApiEffects,
      TeamKnowledgeApiEffects,
      TeamIssuesApiEffects,
      AttachmentsApiEffects,
      SideBarApiEffects
    ]),
    provideStoreDevtools({
      name: 'semco ONE'
    }),
    provideRouter(routes.default),
    provideEntityData({entityMetadata: entityMetadataMap}),
    importProvidersFrom(
      IonicModule.forRoot({
        spinner: 'dots',
        swipeBackEnabled: false,
      }),
      IonicStorageModule.forRoot({
        name: 'one',
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage
        ]
      }),
      HttpClientModule,
      BrowserAnimationsModule,
      EntityDataModule.forRoot({}),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      })
    ),
  ]
});
