<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title [innerText]="'LOGIN.PAGE_NAME' | translate"></ion-title>
    <ion-buttons slot="start" *ngIf="changeRegisterView">
      <ion-button (click)="changeRegisterView = !changeRegisterView">
        <ion-icon name="arrow-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-spinner *ngIf="isLoading$ | async"></ion-spinner>
  <div *ngIf="!(isLoading$ | async)" class="modal-center">
    <app-login-card (formChangeEvent)="onFormChange($event)" (userSubmit)="onUserSubmit($event)"
                    (toRegisterUser)="onRegisterUser($event)" (toReset)="onToReset()"></app-login-card>
  </div>
</ion-content>

