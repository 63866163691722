import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {TeamPage} from "./pages/team/team.page";
import {ChatPage} from "./pages/chat/chat.page";
import {InfosPage} from "./pages/infos/infos.page";
import {KnowledgePage} from "./pages/knowledge/knowledge.page";
import {ChatResolver} from "./pages/chat/services/chat-resolver";
import {
  KnowledgeDetailStartComponent
} from "@team/pages/knowledge/components/knowledge-detail-start/knowledge-detail-start.component";
import {knowledgeDetailResolver} from "@team/pages/knowledge/store/knowledge-detail.resolver";
import {DocumentsPage} from "@team/pages/documents/documents.page";
import {TeamLoginPage} from "@team/pages/login/login.page";
import {TeamChangePasswordComponent} from "@team/pages/change-password/change-password.component";
import {TeamLoginChangePasswordGuard} from "@team/pages/change-password/guards/login-change-password.guard";
import {teamLoginChangePasswordResolver} from "@team/pages/login/store/team-login-change-password.resolver";
import {inject} from "@angular/core";
import {AppType} from "@shared/models/AppType";
import {TeamAppLoginGuard} from "@team/guard/app-login.guard";
import {ResetPasswordComponent} from "@team/pages/reset-password/reset-password.component";
import {IssuesPage} from "@team/pages/issues/issues.page";
import {TeamIssueReadGuard} from "@team/pages/issues/components/issue-read/guards/team-issue-read.guards";
import {BaseIssueReadComponent} from "@shared/components/issues/components/base-issue-read/base-issue-read.component";
import {
  BaseIssuesTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/base-issues-template-preview.component";
import {WelcomePage} from "@team/pages/welcome/welcome.page";
import {TeamWelcomeGuard} from "@team/pages/welcome/guards/team-welcome.guard";
import {TeamLoginPageGuard} from "@team/pages/login/guards/team-login-page.guard";
import {
  BaseIssueSuccessComponent
} from "@shared/components/issues/components/base-issue-success/base-issue-success.component";
import {ArticlesPage} from "@team/pages/knowledge/components/articles/articles.page";
import {articlesPageResolver} from "@team/pages/knowledge/store/articles-page.resolver";
import {
  BaseIssueTemplateComponent
} from "@shared/components/issues/components/base-issue-template/base-issue-template.component";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'team',
  },
  {
    path: 'team',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMath: 'full'
      },
      {
        path: 'welcome',
        component: WelcomePage,
        canActivate: mapToCanActivate([TeamWelcomeGuard])
      },
      {
        path: 'login',
        component: TeamLoginPage,
        canActivate: mapToCanActivate([TeamLoginPageGuard])
      },
      {
        path: 'login/reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'login/change-password',
        component: TeamChangePasswordComponent,
        resolve: {loginData: teamLoginChangePasswordResolver},
        canActivate: mapToCanActivate([TeamLoginChangePasswordGuard])
      },
      {
        path: 'logged',
        component: TeamPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(TeamAppLoginGuard).canActivate((AppType.Team), state)
        ],
        children: [
          {
            path: '',
            redirectTo: 'infos',
          },
          {
            path: 'infos',
            component: InfosPage,
          },
          {
            path: 'chat',
            component: ChatPage,
            resolve: {
              chat: ChatResolver,
            },
          },
          {
            path: 'knowledge',
            component: KnowledgePage,
          },
          {
            path: 'knowledge/:id',
            component: ArticlesPage,
            resolve: {article: articlesPageResolver}
          },
          {
            path: 'knowledge/:id/start',
            component: KnowledgeDetailStartComponent,
            resolve: {detail: knowledgeDetailResolver},
          },
          {
            path: 'documents',
            component: DocumentsPage,
          },
          {
            path: 'documents/:path',
            component: DocumentsPage,
            pathMatch: 'full',
          },
          {
            path: 'issues',
            component: IssuesPage,
          },
          {
            path: 'issues/new',
            component: BaseIssueTemplateComponent
          },
          {
            path: 'issues/new/:id',
            component: BaseIssuesTemplatePreviewComponent,
            pathMatch: 'full',
          },
          {
            path: 'issues/success',
            component: BaseIssueSuccessComponent,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: BaseIssueReadComponent,
            canActivate: mapToCanActivate([TeamIssueReadGuard]),
            pathMatch: 'full',
          },
        ]
      },

    ],
  },
] as Route[];

