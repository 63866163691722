import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormGroup} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {FormPasswordComponent} from "@shared/components/form-password/form-password.component";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {DispatchFormData} from "@shared/components/form-password/interfaces/dispatch-form-data.interface";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-new-password-dialog',
  templateUrl: './new-password-dialog.component.html',
  styleUrls: ['./new-password-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormPasswordComponent
  ],
  standalone: true
})
export class NewPasswordDialogComponent implements OnInit {

  @Input() newPasswordData: { token: string, email: string };

  shadowForm: FormGroup;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;

  constructor(
    private _modalControllerService: ModalControllerService,
    private _store: Store<TeamLoginState>
  ) {
    this.newPasswordData = <{ token: string, email: string }>{};
    this.shadowForm = new FormGroup({});
    this.isLoading$ = of(false);
    this.isLoadingId$ = of(NaN);
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof NewPasswordDialogComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name onFormChange
   * @description
   * use a shadow copy from the emitted form child component
   * @memberof NewPasswordDialogComponent
   * @param form
   */
  onFormChange(form: FormGroup): void {
    this.shadowForm = form;
  }

  /**
   * @name submitForm
   * @description
   * save need, it variables to call the dispatch action
   * @memberof NewPasswordDialogComponent
   */
  submitForm(): void {
    const newPassword = this.shadowForm.value.newPassword;

    const dispatchData: DispatchFormData = {
      newPassword: newPassword,
      newPasswordBase64: btoa(newPassword),
      confirmPassword: this.shadowForm.value.confirmPassword,
      email: this.shadowForm.value.email
    };

    if (dispatchData.newPassword === dispatchData.confirmPassword) {
      this.dispatchNewPassword(dispatchData);
    }
  }

  /**
   * @name dispatchNewPassword
   * @description
   * dispatch the resetPassword action
   * @memberof NewPasswordDialogComponent
   * @param data
   */
  dispatchNewPassword(data: DispatchFormData): void {
    this._store.dispatch(
      TeamLoginActions.resetPassword({
        resetData: {
          token: this.newPasswordData?.token,
          newPassword: data.newPassword,
          newPasswordBase64: data.newPasswordBase64,
          email: data.email
        }, isLoadingId: this.newPasswordData?.token
      })
    );
  }

  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }
}
