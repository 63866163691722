import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ShowHidePasswordComponent} from "@features/show-hide-password/show-hide-password.component";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {InputNumericComponent} from "@shared/components/custom-property/inputs/number/input-numeric.component";
import {
  InvitationCodeFormComponent
} from "@team/pages/login/components/invitation-code-dialog/invitation-code-form/invitation-code-form.component";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {select, Store} from "@ngrx/store";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-invitation-code-dialog',
  templateUrl: './invitation-code-dialog.component.html',
  styleUrls: ['./invitation-code-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ShowHidePasswordComponent,
    InputTextComponent,
    InputNumericComponent,
    InvitationCodeFormComponent
  ],
  standalone: true
})
export class InvitationCodeDialogComponent implements OnInit {

  @Input() code: string;
  @Output() toReset: EventEmitter<any>;
  @Output() userSubmit: EventEmitter<{ input: { username: string, password: string }}>;

  invitationCodeFormShadow: FormGroup;
  pin: string;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;

  constructor(
    private _formBuilder: FormBuilder,
    private _modalControllerService: ModalControllerService,
    private _store: Store
  ) {
    this.code = "";
    this.pin = "emailPinId";
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
    this.toReset = new EventEmitter<any>();
    this.userSubmit = new EventEmitter<{ input: { username: string, password: string }}>();
    this.invitationCodeFormShadow = this._formBuilder.group({
      lastName: ['', [Validators.required]],
      pin: ['', [Validators.required]]
    });
  }

  getControl(controlName: string) {
    return this.invitationCodeFormShadow.get(controlName) as FormControl;
  }


  ngOnInit() {
  }

  onFormChange(form: FormGroup): void {
    this.invitationCodeFormShadow = form;
  }

  exit(): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestAcceptCancel());
    this._modalControllerService.closeModal();
  }

  submit(): void {
    const lastName = this.invitationCodeFormShadow.value.lastName;
    const pin = this.invitationCodeFormShadow.value.pin.toString();

    this.dispatchInvitationCodeCheck(lastName, pin);
  }

  dispatchInvitationCodeCheck(lastName: string, pin: string): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestAccept({code: this.code, lastName, pin, isLoadingId: this.pin}))
  }

  checkLoadingButton(pinId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (this.pin! === id) && isLoading)
    );
  }

}
