import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  InvitationCodeFormComponent
} from "@team/pages/login/components/invitation-code-dialog/invitation-code-form/invitation-code-form.component";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {select, Store} from "@ngrx/store";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-invitation-team-code-dialog',
  templateUrl: './invitation-team-code-dialog.component.html',
  styleUrls: ['./invitation-team-code-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputTextComponent,
    InvitationCodeFormComponent
  ],
  standalone: true
})
export class InvitationTeamCodeDialogComponent implements OnInit {

  @Output() toReset: EventEmitter<any>;
  @Output() userSubmit: EventEmitter<{ input: { username: string, password: string } }>;

  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;
  teamCodeForm: FormGroup;
  buttonId: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _modalControllerService: ModalControllerService,
    private _store: Store
  ) {
    this.isLoading$ = of(true);
    this.isLoadingId$ = of(NaN);
    this.buttonId = "codeButtonId";
    this.toReset = new EventEmitter<any>();
    this.userSubmit = new EventEmitter<{ input: { username: string, password: string } }>();
    this.teamCodeForm = this._formBuilder.group({
      code: ['', [Validators.required]],
    });
  }

  getControl(controlName: string) {
    return this.teamCodeForm.get(controlName) as FormControl;
  }


  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
  }

  onFormChange(form: FormGroup): void {
    this.teamCodeForm = form;
  }

  exit(): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestCheckDialogClose());
    this._modalControllerService.closeModal();
  }

  submit(): void {
    const code = this.teamCodeForm.value.code;
    this.dispatchInvitationCodeCheck(code);
  }

  dispatchInvitationCodeCheck(code: string): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestCheck({code, isLoadingId: this.buttonId}))
  }

  isRequired(controlName: string): boolean {
    const control = this.teamCodeForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }

  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }

}
