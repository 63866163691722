import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {withLatestFrom} from "rxjs";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

export const teamLoginChangePasswordResolver: ResolveFn<{ email: string, passwordBase64: string } | null | undefined> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const store: Store<TeamLoginState> = inject(Store);

        return store.pipe(
            withLatestFrom(store.pipe(select(TeamLoginSelectors.selectCurrentLogin))),
            map(([_, currentLogin]) => currentLogin)
        );
    };
