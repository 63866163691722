import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TeamLoginState} from "./team-login.state";

export const selectLogin = createFeatureSelector<TeamLoginState>('login');

export const selectCurrentApp = createSelector(
    selectLogin,
    (state: TeamLoginState) => state.currentApp
);

export const selectIsLogging = createSelector(
  selectLogin,
  (state: TeamLoginState) => state.isLogging
);

export const selectIsLogged = createSelector(
  selectLogin,
  (state: TeamLoginState) => state.isLogged
);
