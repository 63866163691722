import {createReducer, on} from "@ngrx/store";
import {InfosState} from "@team/pages/infos/store/infos.state";
import {InfosActions} from './infos.actions-type';
import {environment} from "@env-team/environment";

export const initialInfosState: InfosState = {
  page: {},
  items: [],
  paging: {
    totalItems: 0,
    pageNumber: 0,
    pageSize: environment?.apiUrl?.pageDefaultSize || 1,
    totalPages: 1
  },
  isLoading: true,
  isLoadingId: NaN
};

export const teamInfosStore = createReducer(
  initialInfosState,
  on(InfosActions.postItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(InfosActions.postItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(InfosActions.postItemsPaginatedSuccess, (state, {data}) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(InfosActions.postItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(InfosActions.postItemsPaginatedRefresh, (state, {data}) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(InfosActions.postItemsMyRead, (state, data) => ({
    ...state,
    isLoading: false,
    isLoadingId: data.id
  })),
  on(InfosActions.postItemsMyReadSuccess, (state, {updatedItems, data}) => ({
      ...state,
      items: updatedItems,
      isLoadingId: undefined,
      isLoading: false
    })
  ),
  on(InfosActions.postItemsMyReadFail, (state) => ({
    ...state,
    isLoading: false
  }))
);
