import {Injectable} from "@angular/core";
import {TeamHttpClientBaseApiService} from "@team/services/http-client-base-api/team-http-client-base-api.service";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Observable} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {Orders, OrgaFolderItemDto} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService extends TeamHttpClientBaseApiService {

  /**
   * @name getDocumentsItems
   * @description
   * make a request throw the http client base api to get the documents items
   * @memberof DocumentsApiService
   * @param tenantId
   * @param path
   * @param params
   */
  getDocumentsItems(tenantId: number, path: string, params: PaginationParams): Observable<OrgaResponse<OrgaFolderItemDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: params.sortField!,
        Sort: params.sort!.toString()!,
        SortThenField: params.sortThenField!,
        SortThen: params.sortThen!.toString()!,
        path
      }
    }
    return super.get<OrgaResponse<OrgaFolderItemDto[]>>(`/orga/document/tenant/${tenantId}`,
      undefined, options)
  }

  /**
   * @name getDocumentsBySearch
   * @description
   * make a request to search documents by search text
   * @memberof DocumentsApiService
   * @param searchText
   * @param path
   * @return {Observable<OrgaResponse<OrgaFolderItemDto[]>>}
   */
  getDocumentsBySearch(searchText: string, path: string): Observable<OrgaResponse<OrgaFolderItemDto[]>> {
    const options = {
      params: {
        SortField: "Type",
        Sort: Orders.Descending.toString(),
        SortThenField: "Name",
        SortThen: Orders.Ascending.toString(),
        searchText,
        path
      }
    }
    return super.get<OrgaResponse<OrgaFolderItemDto[]>>(`/orga/document/search`, undefined, options);

  }
}
