<ion-header>
  <ion-toolbar color="primary">
    <ion-title [innerText]="'LOGIN.NEW_PASSWORD.TITLE' | translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exit()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-card>
    <ion-card-content>
      <app-form-password (formChange)="onFormChange($event)" [displaySubmit]="false"
                         [newPasswordData]="newPasswordData"></app-form-password>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <ion-button class="submit" expand="full" (click)="submitForm()" [disabled]="shadowForm.invalid">
    <ng-container *ngIf="!(checkLoadingButton(this.newPasswordData?.token!) | async)">
      {{ 'LOGIN.NEW_PASSWORD.FORM.BUTTONS.SUBMIT' | translate }}
    </ng-container>
    <ion-spinner *ngIf="(checkLoadingButton(this.newPasswordData?.token!) | async)"></ion-spinner>
  </ion-button>
</ion-footer>
