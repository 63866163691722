import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule, NavController} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {OrgaFolderItemDto} from "@server-models";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    AttachmentsComponent
  ]
})
export class DocumentsListComponent {

  @Input() documentList: OrgaFolderItemDto[] | null;
  @Input() document!: OrgaFolderItemDto;
  @Output() pathChange: EventEmitter<{ currentPath: string }> = new EventEmitter<{ currentPath: string }>();
  @Output() needMoreDoc: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousPath: string;

  constructor(
    private _navController: NavController) {
    this.documentList = [];
    this.previousPath = "";
  }

  /**
   * @name openFolder
   * @description
   * open a folder if type folder and navigate to the item.path on click
   * @memberof DocumentsListComponent
   * @param item
   */
  openFolder(item: OrgaFolderItemDto): void {
    if (item.type == 2) {
      this.pathChange.emit({currentPath: item.path!});
      this.previousPath = item.path!;

      this._navController.navigateForward(['team/logged/documents'], {queryParams: {path: item.path}})
    }
  }

}
