import {EffectsBase} from "@core/base.effects";
import {Observable} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {InfosActions} from "@team/pages/infos/store/infos.actions-type";
import {Injectable} from "@angular/core";
import {InfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class TeamInfosEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract postPaginated(action: Observable<InfosRequestPagination>): Observable<any>;

  abstract postMyRead(action: Observable<any>): Observable<any>;


  postPaginated$ = createEffect(() => this.actions().pipe(
    ofType(InfosActions.postItemsPaginated),
    action => this.postPaginated(action)
  ));

  postMyRead$ = createEffect(() => this.actions().pipe(
    ofType(InfosActions.postItemsMyRead),
    action => this.postMyRead(action)
  ));


}
