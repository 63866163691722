import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {TeamIssuesMessageApiService} from "@team/pages/issues/services/team-issues-message-api.service";
import {TeamIssuesMissionApiService} from "@team/pages/issues/services/team-issues-mission-api.service";
import {TeamIssuesTicketApiService} from "@team/pages/issues/services/team-issues-ticket-api.service";

@Injectable({
  providedIn: 'root',
})
export class TeamIssuesService {
  constructor(
    private _teamIssuesMessageApiService: TeamIssuesMessageApiService,
    private _teamIssuesMissionApiService: TeamIssuesMissionApiService,
    private _teamIssuesTicketApiService: TeamIssuesTicketApiService,
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): TeamIssuesMessageApiService | TeamIssuesMissionApiService | TeamIssuesTicketApiService {
    switch (issueType) {
      case IssueType.Message:
        return this._teamIssuesMessageApiService;
      case IssueType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueType.Ticket:
        return this._teamIssuesTicketApiService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${issueType}`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): TeamIssuesMessageApiService | TeamIssuesMissionApiService | TeamIssuesTicketApiService {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._teamIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._teamIssuesTicketApiService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${issueContentType}`);
    }
  }


}
