import {createAction, props} from "@ngrx/store";
import {AcceptedInvitationDto, MeDto, RegisterUserDto, ResetPasswordDto, TokenDto} from "@server-models";
import {AppType} from "@shared/models/AppType";
import {TeamTenantInfoSettingsInterface} from "@team/pages/login/interfaces/team-tenant-info-settings.interface";
import {RegisterGuestAction} from "@team/pages/login/interfaces/register-guest-action.interface";

export const init = createAction(
  '[Login-Team] Init'
);

export const initSuccess = createAction(
  '[Login-Team] Init Success',
  props<{ app: AppType, token?: TokenDto, currentTenant?: TeamTenantInfoSettingsInterface, isLogging?: boolean }>()
);

export const initFail = createAction(
  '[Login-Team] Init Fail',
  props<{ error: any }>()
);

export const loadStorage = createAction(
  '[Login-Team] Load Storage Start'
);

export const loadStorageSuccess = createAction(
  '[Login-Team] Load Storage Success',
  props<{
    tenantSettings: any,
    tenantId: number,
    tenantLanguage: string,
    isGuest: boolean,
    tenantDisplayName: string,
    tokenDto: TokenDto,
    app: AppType,
    isLogging: boolean
  }>()
);

export const loadStorageFail = createAction(
  '[Login-Team] Load Storage Fail',
  props<{ error: any }>()
);

export const byPassword = createAction(
  '[Login-Team] By Password',
  props<{ login: string, password: string | undefined, passwordBase64?: string }>()
);

export const byPasswordSuccess = createAction(
  '[Login-Team] By Password Success',
  props<{ token: TokenDto }>()
);

export const byPasswordFail = createAction(
  '[Login-Team] By Password Fail',
  props<{ error: any }>()
);

export const initRegistrationGuest = createAction(
  '[Login-Team] Registration Guest Start',
  props<{ isLoadingId: number | string, currentEmail: string | undefined }>()
);

export const initRegistrationGuestDialogOpen = createAction(
  '[Login-Team] Registration Guest Dialog Open',
  props<{ currentEmail: string | undefined }>()
);

export const initRegistrationGuestDialogClose = createAction(
  '[Login-Team] Registration Guest Dialog Close'
);

export const initRegistrationGuestSuccess = createAction(
  '[Login-Team] Registration Guest Success'
);

export const initRegistrationGuestFail = createAction(
  '[Login-Team] Registration Guest Fail',
  props<{ error: any }>()
);

export const registerGuestPinRequest = createAction(
  '[Login-Team] Guest Pin Request Start',
  props<{ registerGuest: RegisterGuestAction, isLoadingId?: number | string }>()
);

export const registerGuestPinRequestSuccess = createAction(
  '[Login-Team] Guest Pin Request Success',
  props<RegisterGuestAction>()
);

export const registerGuestPinRequestFail = createAction(
  '[Login-Team] Guest Pin Request Fail',
  props<{ error: any }>()
);

export const registerGuestPinDialog = createAction(
  '[Login-Team] Guest Pin Dialog Start',
  props<any>()
);

export const registerGuestPinDialogCancel = createAction(
  '[Login-Team] Guest Pin Dialog Cancel'
);

export const registerGuestAsUser = createAction(
  '[Login-Team] Guest As User Start',
  props<{ data: RegisterUserDto, isLoadingId: number | string, isRetry?: boolean }>()
);

export const registerGuestAsUserSuccess = createAction(
  '[Login-Team] Guest As User Success',
  props<{
    email: string;
    passwordBase64: string;
    isRetry: boolean;
  }>()
);

export const registerGuestAsUserFail = createAction(
  '[Login-Team] Guest As User Fail',
  props<{ error: any }>()
);

export const initInvitationCodeGuest = createAction(
  '[Login-Team] Guest Invitation Code Start'
);

export const invitationCodeGuestCheck = createAction(
  '[Login-Team] Guest Invitation Code Check Start',
  props<{ code: string, isLoadingId: number | string }>()
);

export const invitationCodeGuestCheckDialogOpen = createAction(
  '[Login-Team] Guest Invitation Code Check Dialog Open'
);

export const invitationCodeGuestCheckDialogClose = createAction(
  '[Login-Team] Guest Invitation Code Check Dialog Close'
);

export const invitationCodeGuestCheckSuccess = createAction(
  '[Login-Team] Guest Invitation Code Check Success',
  props<{ code: string }>()
);

export const invitationCodeGuestCheckRevoked = createAction(
  '[Login-Team] Guest Invitation Code Check Revoked'
);

export const invitationCodeGuestCheckRevokedDisplayed = createAction(
  '[Login-Team] Guest Invitation Code Revoked Displayed'
);

export const invitationCodeGuestCheckFail = createAction(
  '[Login-Team] Guest Invitation Code Check Fail',
  props<{ error: any }>()
);

export const initInvitationCodeGuestSuccess = createAction(
  '[Login-Team] Guest Invitation Code Success'
);

export const initInvitationCodeGuestFail = createAction(
  '[Login-Team] Guest Invitation Code Fail',
  props<{ error: any }>()
);

export const invitationCodeDialogOpen = createAction(
  '[Login-Team] Guest Invitation Code Dialog Open',
  props<{ code: string }>()
);

export const invitationCodeGuestAccept = createAction(
  '[Login-Team] Guest Invitation Code Accept Start',
  props<{ code: string, lastName: string, pin: string, isLoadingId: string }>()
);

export const invitationCodeGuestAcceptCancel = createAction(
  '[Login-Team] Guest Invitation Code Accept Cancel'
);

export const invitationCodeGuestAcceptSuccess = createAction(
  '[Login-Team] Guest Invitation Code Accept Success',
  props<AcceptedInvitationDto>()
);

export const invitationCodeGuestAcceptFail = createAction(
  '[Login-Team] Guest Invitation Code Accept Fail',
  props<{ error: any }>()
);
export const initLogout = createAction(
  '[Login-Team] Logout'
);

export const changePassword = createAction(
  '[Login-Team] Change Password start',
  props<{ email: string, oldPasswordBase64: string, newPasswordBase64: string }>()
);

export const changePasswordSuccess = createAction(
  '[Login-Team] Change Password success'
);

export const changePasswordFail = createAction(
  '[Login-Team] Change Password fail',
  props<{ error: any }>()
);

export const resetPassword = createAction(
  '[Login-Team] Reset Password start',
  props<{ resetData: ResetPasswordDto, isLoadingId: number | string }>()
);

export const resetPasswordSuccess = createAction(
  '[Login-Team] Reset Password success',
  props<{ email: string, password: string }>()
);

export const resetPasswordFail = createAction(
  '[Login-Team] Reset Password fail',
  props<{ error: any }>()
);

export const resetRequestPassword = createAction(
  '[Login-Team] Reset Request Password start',
  props<{ email: string, isLoadingId: number | string }>()
);

export const resetRequestPasswordSuccess = createAction(
  '[Login-Team] Reset Request Password success'
);

export const resetRequestPasswordFail = createAction(
  '[Login-Team] Reset Request Password fail',
  props<{ error: any }>()
);

export const successUniqueTenant = createAction(
  '[Login-Team] Unique tenant Success',
  props<{ app: AppType, token?: TokenDto, currentTenant: TeamTenantInfoSettingsInterface, isLogging: boolean }>()
);

export const successMultiTenant = createAction(
  '[Login-Team] Multi tenant Success',
  props<{ tokenInfo: MeDto }>()
);

export const existingMultiTenant = createAction(
  '[Login-Team] Multi tenant Existing',
  props<{ tenantId: number }>()
);

export const cancelSelectedTenant = createAction(
  '[Login-Team] Selected tenant Cancel'
);

export const successSelectedTenant = createAction(
  '[Login-Team] Selected tenant Success',
  props<{ tenantId?: number, tenantDisplayName?: string, isLogging?: boolean }>()
);

export const fetchTenantSettings = createAction(
  '[Login-Team] Fetch Tenant Settings Start',
  props<{ tenantId: number }>()
);

export const fetchTenantSettingsCancel = createAction(
  '[Login-Team] Fetch Tenant Settings Cancel'
);

export const fetchTenantSettingsSuccess = createAction(
  '[Login-Team] Fetch Tenant Settings Success',
  props<{ currentTenant: TeamTenantInfoSettingsInterface }>()
);

export const fetchTenantSettingsFail = createAction(
  '[Login-Team] Fetch Tenant Settings Fail',
  props<{ error: any }>()
);

export const isLoggingNavigation = createAction(
  '[Login-Team] Login navigation is Logging Start'
);

export const isLoggingNavigationDone = createAction(
  '[Login-Team] Login navigation is Logging Done'
);

export const isLoggingNavigationCanceled = createAction(
  '[Login-Team] Login navigation is Logging Canceled'
);

export const navigationToInfos = createAction(
  '[Login-Team] Navigate to Infos page'
);

export const navigationToWelcome = createAction(
  '[Login-Team] Navigate to welcome page'
);
