import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {filter, Observable} from "rxjs";
import {KnowledgePathListDto} from "@server-models";
import {select, Store} from "@ngrx/store";
import {selectKnowledgePathList, selectKnowledgePathLoading} from "@team/pages/knowledge/store/knowledge.selectors";
import {KnowledgeState} from "@team/pages/knowledge/store/knowledge.state";
import {RouterLink} from "@angular/router";
import {tap} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {KnowledgeCardComponent} from "@team/pages/knowledge/components/knowledge-card/knowledge-card.component";
import {ButtonTeamSideMenuComponent} from "@team/components/button-team-side-menu/button-team-side-menu.component";
import {BaseSearchBarComponent} from "@shared/components/search-bar/components/base-search-bar.component";
import {KnowledgeActions} from "@team/pages/knowledge/store/knowledge.actions-type";

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.page.html',
  styleUrls: ['./knowledge.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterLink, KnowledgeCardComponent, ButtonTeamSideMenuComponent, BaseSearchBarComponent]
})
export class KnowledgePage implements OnInit {

  knowledgePathData$: Observable<KnowledgePathListDto[]>;
  knowledgePathIsLoading$: Observable<boolean>;
  paginationParams: PaginationParams;
  infinityLoading: boolean;

  ownLoad: boolean;
  displaySearchBar: boolean;

  constructor(private _store: Store<KnowledgeState>) {
    this.knowledgePathData$ = this._store.pipe(select(selectKnowledgePathList));
    this.knowledgePathIsLoading$ = this._store.pipe(select(selectKnowledgePathLoading));
    this.ownLoad = false;
    this.infinityLoading = false;
    this.displaySearchBar = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sort: 1
    };
  }

  ngOnInit() {
    this.loadKnowledgePathList();
  }

  /**
   * @name loadKnowledgePathList
   * @description
   * launch the action to load knowledge path list by a get params
   * @memberof KnowledgePage
   * @param refresh
   */
  loadKnowledgePathList(refresh: boolean = false): void {
    this._store.dispatch((KnowledgeActions.getPathItemsPaged({
      params: this.paginationParams,
      refresh
    })));
  }

  /**
   * @name loadMoreKnowledgePath
   * @description
   * request the launch once the infinity scroll bottom is reached
   * @memberof KnowledgePage
   * @param event
   */
  loadMoreKnowledgePath(event: any): void {
    this.paginationParams.pageNumber++;
    this.infinityLoading = true;
    this.loadKnowledgePathList();
    this.knowledgePathIsLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        event.target.complete();
        this.infinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name changeDisplayBar
   * @description
   * change boolean value of display search bar to hide or display
   * @memberof DocumentsPage
   */
  changeDisplayBar() {
    this.displaySearchBar = !this.displaySearchBar;
    if (!this.displaySearchBar) {
      this.loadKnowledgePathList(true);
    }
  }

  /**
   * @name onSearch
   * @description
   * trigger the event from base search bar to dispatch a search or refresh load documents
   * @memberof DocumentsPage
   * @param value
   */
  onSearch(value: string): void {
    if (value) {
      this._store.dispatch(KnowledgeActions.getItemsSearched({toSearch: value}))
    } else {
      this.loadKnowledgePathList(true);
    }
  }

  /**
   * @name handleRefresh
   * @description
   * Use to reset the pageNumber param, and call with refresh flag loadKnowledgePath
   * @memberof KnowledgePage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.ownLoad) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.ownLoad = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadKnowledgePathList(true);

    this.knowledgePathIsLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.ownLoad = false;
      })
    ).subscribe();
  }

}
