import {Component, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {Storage} from "@ionic/storage-angular";
import {Store} from "@ngrx/store";
import {appInit} from "@core/store/app.actions";
import {EntityDataService} from "@ngrx/data";
import {ChatDataService} from "@team/pages/chat/services/chat-data.service";
import {environment} from "@env-team/environment";
import {LanguageService} from "@shared/services/language.service";

@Component({
  selector: 'app-root',
  templateUrl: 'team-app.component.html',
  styleUrls: ['team-app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class TeamAppComponent implements OnInit {


  constructor(
    private _entityDataService: EntityDataService,
    private _chatService: ChatDataService,
    private _storage: Storage,
    private _store: Store,
    private _languageService: LanguageService,
  ) {
    this._ngrxDataServiceRegister();
    this._languageService.setDefaultLanguageByEnvironment(environment);
  }

  async ngOnInit() {
    this._store.dispatch(appInit());
    await this._storage.create();
  }

  /**
   * @name _ngrxDataServiceRegister
   * @description
   * Info NgRx Data to use this services over the default behaviour
   * @private
   */
  private _ngrxDataServiceRegister(): void {
    // this code is to info the NgRx data to don't use the default request behaviour
    this._entityDataService.registerServices({
      Chat: this._chatService
    })
  }
}
