<ion-spinner *ngIf="isLoading$ | async"></ion-spinner>

<div *ngIf="!(isLoading$ | async)" class="ion-page" id="main-content">
  <ion-content class="ion-padding ion-text-center">
    <div class="centered-content">
      <h1><b>{{ 'TEAM.WELCOME.PAGE_NAME' | translate }}</b></h1>
      <p>{{ 'TEAM.WELCOME.DESCRIPTION' | translate }}</p>
      <ion-button (click)="joinTeam()"
                  [innerText]="'TEAM.WELCOME.BUTTONS.JOIN_TEAM' | translate"></ion-button>
      <ion-button (click)="logOut()" fill="outline"
                  [innerText]="'TEAM.WELCOME.BUTTONS.LOGOUT' | translate"></ion-button>
    </div>
  </ion-content>
</div>

