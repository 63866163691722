<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title [innerText]="'TEAM.CHATS.PAGE_NAME' | translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large" [innerText]="'TEAM.CHATS.PAGE_NAME' | translate"></ion-title>
    </ion-toolbar>
  </ion-header>
</ion-content>
