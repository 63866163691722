import {createReducer, on} from "@ngrx/store";
import {SideBarActions} from "@team/components/side-bar/store/side-bar.actions-type";
import {SideBarState} from "@team/components/side-bar/store/side-bar.state";

export const initialSideBar: SideBarState = <SideBarState>{
  name: "",
  path: "",
  file: <Blob>{},
  logo: "",
  isLoading: true,
  isLoadingId: NaN
};

export const sideBarStore = createReducer(
  initialSideBar,
  on(SideBarActions.getLogo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(SideBarActions.getLogoSuccess, (state, args) => ({
      ...state,
      logo: args.imgLogo,
      isLoading: false,
    })),
  on(SideBarActions.getLogoFail, (state) => ({
    ...state,
    isLoading: false
  })),
  );
