import {Injectable} from "@angular/core";
import {EffectsBase} from "@core/base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DocumentsActions} from "@team/pages/documents/store/documents.actions-type";
import {Observable} from "rxjs";
import {DocumentsRequestPagination} from "@team/pages/documents/interfaces/documents-request-pagination.interface";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";

@Injectable({
  providedIn: 'root'
})
export abstract class TeamDocumentsEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getPagination(action: Observable<DocumentsRequestPagination>): Observable<OrgaResponse<OrgaFolderItemDto[]> | any>;

  abstract getItemsSearched(action: Observable<{ toSearch: string, path: string }>): Observable<OrgaResponse<OrgaFolderItemDto[]> | any>;

  getPagination$ = createEffect(() => this.actions().pipe(
    ofType(DocumentsActions.getItemsPaginated),
    action => this.getPagination(action)
  ));

  getItemsSearched$ = createEffect(() => this.actions().pipe(
    ofType(DocumentsActions.getItemsSearched),
    action => this.getItemsSearched(action)
  ));
}
