import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {FileService} from "@shared/services/file.service";
import {SideBarEffectsBase} from "@team/components/side-bar/store/side-bar.effects";
import {mergeMap, Observable, of, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {SideBarActions} from "@team/components/side-bar/store/side-bar.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Injectable({
  providedIn: 'root'
})
export class SideBarApiEffects extends SideBarEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _fileService: FileService,
  ) {
    super(store, actions$);
  }

  getLogo(action: Observable<any>): Observable<{file: Blob, imgLogo: string, name: string, id: number}> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TeamLoginSelectors.selectTenantSettings))),
      mergeMap(([_, tenantSettings]) => this._requestLogo(tenantSettings?.logo, tenantSettings?.logoId!))
    );
  }

  /**
   * @name _requestLogo
   * @description
   * Request to the file service the logo
   * @memberof SideBarApiEffects
   * @param logo
   * @param logoId
   * @private
   * @returns {Observable<{file: Blob, imgLogo: string, name: string, id: number} | any>}
   */
  private _requestLogo(logo: any, logoId: number): Observable<{file: Blob, imgLogo: string, name: string, id: number} | any> {
    return this._fileService.get(logo?.file?.uri).pipe(
      map((data) => {
        const imgLogo = this._transformBlobToImage(data);
        return SideBarActions.getLogoSuccess({file: data, imgLogo, name: logo?.file?.name, id: logoId})
      }),
      catchError((error) => of(SideBarActions.getLogoFail({error}))
      )
    );
  }

  /**
   * @name _transformBlobToImage
   * @description
   * create a object url from a blob file
   * @memberof SideBarApiEffects
   * @param file
   * @private
   * @returns {string}
   */
  private _transformBlobToImage(file: Blob): string {
    return URL.createObjectURL(file);
  }
}
