<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ 'LOGIN.LABELS.CHOICE_TENANT' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-radio-group [(ngModel)]="selectedRoles">
      <ion-item-group *ngFor="let tenant of sortedData" >
        <ion-item>
          <ion-radio
            justify="start"
            labelPlacement="end"
            [value]="{ tenantId: tenant.tenantId }"
          >
            {{ tenant.name }}
          </ion-radio>
        </ion-item>
      </ion-item-group>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-button class="submit" expand="full" [disabled]="!selectedRoles" (click)="submitSelection()">
    {{ 'LOGIN.BUTTONS.SUBMIT' | translate }}
  </ion-button>
</ion-footer>

