import {Injectable} from "@angular/core";
import {Router, UrlTree} from "@angular/router";
import {Observable, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Injectable({
  providedIn: 'root'
})
export class TeamLoginChangePasswordGuard {

  constructor(private _store: Store, private _router: Router) {
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    return this._store.pipe(
      withLatestFrom(this._store.select(TeamLoginSelectors.selectCurrentLogin)),
      map(([_, currentLogin]) => {
        if (currentLogin) {
          return true;
        } else {
          return this._router.createUrlTree(['team/login']);
        }
      })
    );
  }
}
