import {EventEmitter, Injectable} from "@angular/core";
import {KnowledgeDetailStartEvent} from "../interfaces/knowledge-detail-start-event.interface";

@Injectable({
  providedIn: 'root'
})
export class KnowledgeDetailService {
    titleClicked: EventEmitter<KnowledgeDetailStartEvent> = new EventEmitter<KnowledgeDetailStartEvent>();

    /**
     * @name emitTitleClick
     * @description
     * emit the event<KnowledgeDetailStartEvent>
     * @memberof KnowledgeDetailService
     * @param data
     */
    emitTitleClick(data: KnowledgeDetailStartEvent): void {
        this.titleClicked.emit(data);
    }
}
