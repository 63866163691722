import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, MenuController} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-button-team-side-menu',
  templateUrl: './button-team-side-menu.component.html',
  styleUrls: ['./button-team-side-menu.component.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true
})
export class ButtonTeamSideMenuComponent  implements OnInit {

  @Input() title!: string;
  @Input() showMenu!: boolean;

  constructor(
    private _menuCtrl: MenuController
  ) {
    this.showMenu = true;
  }

  ngOnInit() {

  }

  /**
   * @name openTeamMenu
   * @description
   * Use the menu controller to open the team-menu
   * @memberof ButtonTeamSideMenuComponent
   */
  openTeamMenu() {
    this._menuCtrl.open('team-menu')
  }

}
