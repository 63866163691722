import {createFeatureSelector, createSelector} from "@ngrx/store";
import {InfosState} from "@team/pages/infos/store/infos.state";

export const selectInfosStore = createFeatureSelector<InfosState>('teamInfos');

export const selectInfoPage = createSelector(
    selectInfosStore,
    (state: InfosState) => state.page
);

export const selectInfoPageList = createSelector(
    selectInfosStore,
    (state: InfosState) => state.items
);

export const selectInfoPagePagination = createSelector(
    selectInfosStore,
    (state: InfosState) => state.paging
);

export const selectInfoPageLoading = createSelector(
    selectInfosStore,
    (state: InfosState) => state.isLoading
);

export const selectInfoPageLoadingId = createSelector(
  selectInfosStore,
  (state: InfosState) => state.isLoadingId
);
