import {Injectable} from "@angular/core";
import {ErrorHandlerBaseService} from "@core/services/error-handler-base/error-handler-base.service";
import {AlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamErrorHandlerGuestLoginTeamCodeService extends ErrorHandlerBaseService {

  override _formatAlertFor404(status: number): AlertControllerCreate {
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");
    return {
      header: `${this._errorText}: ${status.toString()}`,
      subHeader: '',
      message: this._translateService.instant("TEAM.WELCOME.DIALOGS.TEAM_CODE.ERRORS.404"),
      buttons: [
        {text: 'OK'}
      ]
    };
  }
}
