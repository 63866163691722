<ion-header>
  <ion-toolbar color="primary">
    <ion-title [innerText]="'TEAM.WELCOME.DIALOGS.TEAM_CODE.HEADER' | translate">
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exit()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ 'TEAM.WELCOME.DIALOGS.TEAM_CODE.TITLE' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="space-content">
        <form [formGroup]="teamCodeForm">
          <app-input-text [label]="'TEAM.WELCOME.DIALOGS.TEAM_CODE.INPUTS.CODE.LABEL' | translate"
                          [formControl]="getControl('code')"
                          [isRequired]="isRequired('code')"></app-input-text>
        </form>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-card  class="ion-no-padding card-button">
    <ng-container *ngIf="!(checkLoadingButton(buttonId)| async)">
      <ion-button [disabled]="this.teamCodeForm.invalid"
                  class="submit" expand="block" (click)="submit()">
        {{ 'TEAM.WELCOME.DIALOGS.TEAM_CODE.BUTTONS.SUBMIT' | translate }}
      </ion-button>
    </ng-container>
    <ng-container *ngIf="(checkLoadingButton(buttonId) | async)">
      <ion-button [disabled]="true"
                  class="submit" expand="block">
        <ion-spinner></ion-spinner>
      </ion-button>
    </ng-container>
  </ion-card>
</ion-content>
