import {EffectsBase} from "@core/base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {KnowledgeActions} from "@team/pages/knowledge/store/knowledge.actions-type";
import {
  KnowledgePathRequestPagination
} from "@team/pages/knowledge/interfaces/knowledge-path-request-pagination.interface";
import {Injectable} from "@angular/core";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {KnowledgeArticleListDto} from "@server-models";

@Injectable({
  providedIn: 'root'
})
export abstract class TeamKnowledgeEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getPath(action: Observable<KnowledgePathRequestPagination>): Observable<any>;

  abstract getPathItemsByIdStart(action: Observable<any>): Observable<any>;

  abstract getItemsSearched(action: Observable<{ toSearch: string }>): Observable<OrgaResponse<KnowledgeArticleListDto[]> | any>;

  abstract getArticlePage(action: Observable<any>): Observable<any>;


  getPath$ = createEffect(() => this.actions().pipe(
    ofType(KnowledgeActions.getPathItemsPaged),
    action => this.getPath(action)
  ));

  getPathItemByIdStart$ = createEffect(() => this.actions().pipe(
    ofType(KnowledgeActions.getPathItemsByIdStart),
    action => this.getPathItemsByIdStart(action)
  ));

  getItemsSearched$ = createEffect(() => this.actions().pipe(
    ofType(KnowledgeActions.getItemsSearched),
    action => this.getItemsSearched(action)
  ));

  getArticlePage$ = createEffect(() => this.actions().pipe(
    ofType(KnowledgeActions.getArticlePage),
    action => this.getArticlePage(action)
  ));

}
