import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {SideBarComponent} from "@team/components/side-bar/side-bar.component";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-team',
  templateUrl: './team.page.html',
  styleUrls: ['./team.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SideBarComponent]
})
export class TeamPage implements OnInit {

  isLoading$!: Observable<boolean>;
  currentLogin$!: Observable<boolean>;
  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.select(TeamLoginSelectors.selectIsLoading);

  }

  ngOnInit() {
    this.currentLogin$ = this._store.pipe(select(TeamLoginSelectors.selectCurrentLogin), map((login) => !!login));
  }
}
