import {createAction, props} from "@ngrx/store";
import {InfoListDto} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {InfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";
import {InfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";

export const postItemsPaginated = createAction(
  '[Infos] Post Paginated',
  props<InfosRequestPagination>()
);

export const postItemsPaginatedCancel = createAction(
  '[Infos] Post Paginated Cancel',
);

export const postItemsPaginatedSuccess = createAction(
  '[Infos] Post Paginated success',
  props<{ data: OrgaResponse<InfoListDto[]> }>()
);

export const postItemsPaginatedFail = createAction(
  '[Infos] Post Paginated fail',
  props<{ error: any }>()
);

export const postItemsPaginatedRefresh = createAction(
  '[Infos] Post Paginated Refresh',
  props<{ data: OrgaResponse<InfoListDto[]> }>()
);

export const postItemsMyRead = createAction(
  '[Infos] Post My Read',
  props<{ id: number }>()
);

export const postItemsMyReadSuccess = createAction(
  '[Infos] Post My Read Success',
  props<{ updatedItems: InfoListDto[], data: OrgaResponse<InfosMyReadDto> }>()
);

export const postItemsMyReadFail = createAction(
  '[Infos] Post My Read Fail',
  props<{ error: any }>()
);
