<ion-card>
  <ion-card-header>
    <ion-card-title class="ion-text-uppercase">
      {{ 'LOGIN.CARDS.LOGIN.TITLE' | translate }}
    </ion-card-title>
    <ion-card-subtitle>
      {{ 'LOGIN.CARDS.LOGIN.SUBTITLE' | translate }}
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <div class="space-content">
      <form [formGroup]="loginForm" (change)="formChange()">
        <app-input-text
          [label]="'LOGIN.LABELS.USERNAME' | translate"
          [formControl]="getControl()"
        >
          <app-show-hide-password>
            <ion-item class="ion-no-padding">
              <ion-input label-placement="stacked" [label]="'LOGIN.LABELS.PASSWORD' | translate"
                         type="password"
                         formControlName="password"></ion-input>
            </ion-item>
          </app-show-hide-password>
        </app-input-text>
      </form>
      <ion-button [disabled]="loginForm.invalid" size="default" (click)="userInput()"
                  [innerText]="'LOGIN.BUTTONS.LOGIN' | translate"></ion-button>

      <ion-button  *ngIf="!(checkLoadingButton(buttonId) | async)" fill="outline" size="default" (click)="registerUser()"
                  [innerText]="'LOGIN.BUTTONS.REGISTER_USER' | translate"></ion-button>
      <ion-button  [disabled]="true" *ngIf="(checkLoadingButton(buttonId) | async)" fill="outline" size="default">
        <ion-spinner></ion-spinner>
      </ion-button>
    </div>

    <ion-item-divider mode="md">
    </ion-item-divider>
        <ion-button (click)="gotoReset()" mode="ios" fill="clear">{{ 'LOGIN.CARDS.LOGIN.RESET_NOW' | translate }}
        </ion-button>
  </ion-card-content>
</ion-card>
