import {Component, OnInit, ViewChild} from '@angular/core';
import {IonicModule, NavController} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {KnowledgeArticleStartDto} from "@server-models";
import {KnowledgeDetailService} from "./services/knowledge-detail.service";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {KnowledgeDetailStartEvent} from "./interfaces/knowledge-detail-start-event.interface";
import {animate, style, transition, trigger} from "@angular/animations";
import {IonSplitPane} from "@ionic/angular/standalone";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {
  KnowledgeArticleDetailStartComponent
} from "@shared/components/knowledge-article-detail-start/knowledge-article-detail-start.component";
import {selectKnowledgePathLoading} from "@team/pages/knowledge/store/knowledge.selectors";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-knowledge-detail-start',
  templateUrl: './knowledge-detail-start.component.html',
  animations: [
    trigger('contentChange', [
      transition('* <=> *', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in-out', style({transform: 'translateX(0%)'})),
      ]),
    ])
  ],
  styleUrls: ['./knowledge-detail-start.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    KnowledgeArticleDetailStartComponent
  ],
  standalone: true
})
export class KnowledgeDetailStartComponent implements OnInit {
  @ViewChild('ionSplitPane') ionSplitPane!: IonSplitPane;

  private readonly _currentId: string;
  isLoading$: Observable<boolean>;
  contentData: any;
  contentDataArray: string[];
  titleData: string;
  attachments: any;
  firstLoad: boolean;
  progress: number;
  currentProgress: number;
  firstProgress: number;
  articlesProgressLength: number[];

  articlesByStart: KnowledgeArticleStartDto[];

  constructor(
    private _activatedRouter: ActivatedRoute,
    private _navController: NavController,
    private _store: Store,
    private _knowledgeDetailService: KnowledgeDetailService
  ) {
    this._currentId = this._activatedRouter.snapshot.paramMap.get('id') as string;
    this.isLoading$ = of();
    this.firstLoad = true;
    this.titleData = '';
    this.attachments = [];
    this.articlesProgressLength = [];
    this.progress = 0;
    this.currentProgress = 0;
    this.firstProgress = 0;
    this.contentDataArray = [];
    this.articlesByStart = [];
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(selectKnowledgePathLoading));
    this.articlesByStart = this._activatedRouter.snapshot.data['detail'];
    this._knowledgeDetailService.titleClicked.subscribe((data: KnowledgeDetailStartEvent) => {
      this.handleTitleClick(data);
    });
  }

  /**
   * @name handleTitleClick
   * @description
   * handle the emitted title click
   * @memberof KnowledgeDetailStartComponent
   * @param data
   */
  handleTitleClick(data: KnowledgeDetailStartEvent): void {
    this.firstLoad = false;
    this.contentData = data.content;
    this.titleData = data.title;
    this.attachments = data.attachments;
    this.progress = this.articlesProgressLength[data.currentProgress];
    this.currentProgress = data.currentProgress;
  }

  /**
   * @name loadContent
   * @description
   * emit the values to be load
   * @memberof KnowledgeDetailStartComponent
   * @param data
   */
  loadContent(
    data: {
      content: string | null | undefined,
      title: string | null | undefined,
      attachments: any,
      articlesLength: number,
      currentProgress: number
    }): void {
    this._knowledgeDetailService.emitTitleClick(data as KnowledgeDetailStartEvent);
  }

  /**
   * @name setInitialContent
   * @description
   * Used to set the initial values in the template to avoid undefined values
   * @memberof KnowledgeDetailStartComponent
   * @param articles
   * @return {string}
   */
  setInitialContent(articles: KnowledgeArticleStartDto[]): string {
    this.articlesProgressLength = this._getArticlesProgressLong(articles.length);
    this.progress = this.articlesProgressLength[0];
    this.contentDataArray = articles.map(article => article.content) as string[];
    this.contentData = this.contentDataArray[this.currentProgress];
    return this.contentData;
  }

  /**
   * @name _getArticlesProgressLong
   * @description
   * return an array number calculating the articles length and
   * divide them into an increase array of values between 0 - 1
   * @memberof KnowledgeDetailStartComponent
   * @param articlesLength
   * @private
   * @return {number[]}
   */
  private _getArticlesProgressLong(articlesLength: number): number[] {
    return Array.from({length: articlesLength}, (_, index) => (index + 1) / articlesLength);
  }

  /**
   * @name back
   * @description
   * navigate to previous navigation
   * @memberof KnowledgeDetailStartComponent
   */
  back(): void {
    this._navController.navigateBack('team/logged/knowledge');
  }

  /**
   * @name nextPosition
   * @description
   * increase the currentProgress and update the content
   * @memberof KnowledgeDetailStartComponent
   */
  nextPosition(): void {
    if (this.currentProgress < this.contentDataArray.length - 1) {
      this.currentProgress++;
      this._updateContent();
    }
  }

  /**
   * @name previousPosition
   * @description
   * decrease the currentProgress and update the content
   * @memberof KnowledgeDetailStartComponent
   */
  previousPosition(): void {
    if (this.currentProgress > 0) {
      this.currentProgress--;
      this._updateContent();
    }
  }

  /**
   * @name toggleMenu
   * @description
   * Seems toggleMenu button is with an existing bug: source: https://github.com/ionic-team/ionic-framework/issues/28337
   * the workaround is disabled or enable the splitPane
   * @memberof KnowledgeDetailStartComponent
   */
  toggleMenu(): void {
    this.ionSplitPane.disabled = !this.ionSplitPane.disabled;
  }

  /**
   * @name _updateContent
   * @description
   * update the contentData and the progress
   * @memberof KnowledgeDetailStartComponent
   * @private
   */
  private _updateContent(): void {
    this.contentData = this.contentDataArray[this.currentProgress];
    this.progress = (this.currentProgress + 1) / this.articlesProgressLength.length;
  }

}
