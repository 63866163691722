<form [formGroup]="pinForm">
  <div formArrayName="pin">
    <div class="pin-input-container">
      <input
        *ngFor="let digit of pinDigits; let i = index"
        formControlName="{{ i }}"
        (input)="onInputChange(i, $event)"
        (keydown.backspace)="onRemove(i)"
        (paste)="onPaste($event, i)"
        maxlength="1"
        inputmode="numeric"
        pattern="[0-9]"
        [id]="'pinInput' + i"
      />
    </div>
  </div>
</form>
