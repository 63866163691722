import {Injectable} from "@angular/core";
import {EffectsBase} from "@core/base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {SideBarActions} from "@team/components/side-bar/store/side-bar.actions-type";

@Injectable({
  providedIn: 'root'
})
export abstract class SideBarEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getLogo(action: Observable<any>): Observable<{
    file: Blob,
    imgLogo: string,
    name: string,
    id: number
  } | any>;

  getLogo$ = createEffect(() => this.actions().pipe(
    ofType(SideBarActions.getLogo),
    action => this.getLogo(action)
  ));

}
