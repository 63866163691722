<form [formGroup]="registerUserForm" (ngSubmit)="submitForm()">
  <app-input-email [isRequired]="isRequired('email')"
                   [label]="('LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.LABELS.EMAIL' | translate)"
                   [formControl]="getControl('email')"></app-input-email>
  <ion-list>
    <app-show-hide-password>
      <ion-item class="ion-no-padding flex-item">
        <ion-input label-placement="stacked"
                   [label]="('LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.LABELS.PASSWORD' | translate) + (isRequired('password') ? ' *' : '')"
                   type="password"
                   formControlName="password"></ion-input>
      </ion-item>
    </app-show-hide-password>
    <div class="form-errors">
      <ion-text class="ion-native" color="danger"
                *ngIf="registerUserForm.get('password')!.hasError('minlength')">
        {{ "LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.ERRORS.MINLENGTH" | translate }}
      </ion-text>
    </div>
  </ion-list>

  <app-show-hide-password>
    <ion-item class="ion-no-padding flex-item">
      <ion-input label-placement="stacked"
                 [label]="('LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.LABELS.CONFIRM_PASSWORD' | translate) + (isRequired('confirmPassword') ? ' *' : '')"
                 type="password"
                 formControlName="confirmPassword" required="required"></ion-input>
    </ion-item>
  </app-show-hide-password>
  <div class="form-errors">
    <ion-text class="ion-native" color="danger"
              *ngIf="registerUserForm.get('confirmPassword')!.hasError('minlength')">
      {{ "LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.ERRORS.MINLENGTH" | translate }}
    </ion-text>
    <ion-text class="ion-native" color="danger"
              *ngIf="registerUserForm.touched && registerUserForm.hasError('passwordMismatch')">
      {{ "LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.FORM.ERRORS.MISMATCH" | translate }}
    </ion-text>
  </div>
</form>
