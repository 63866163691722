import {Component, Input, OnInit} from '@angular/core';
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {IonicModule} from "@ionic/angular";
import {NgIf} from "@angular/common";
import {UserHtmlComponent} from "@features/user-html/user-html.component";

@Component({
  selector: 'app-knowledge-article-detail-start',
  templateUrl: './knowledge-article-detail-start.component.html',
  styleUrls: ['./knowledge-article-detail-start.component.scss'],
  imports: [
    AttachmentsComponent,
    IonicModule,
    NgIf,
    UserHtmlComponent
  ],
  standalone: true
})
export class KnowledgeArticleDetailStartComponent  implements OnInit {

  @Input() htmlContent: any;
  @Input() attachmentsInc: any;

  constructor() { }

  ngOnInit() {
  }


}
