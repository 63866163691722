<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title [innerText]="'LOGIN.RESET_PASSWORD.TITLE' | translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" (ngSubmit)="submitReset()">
    <ion-list>
      <ion-item class="ion-no-padding">
        <ion-input label-placement="stacked"
                   [label]="'LOGIN.FORM-PASSWORD.LABELS.EMAIL' | translate"
                   formControlName="email" type="email">
        </ion-input>
      </ion-item>
    </ion-list>
    <ion-button expand="full" type="submit" [disabled]="!form.valid || !!(isLoading$ | async)">
      <ng-container *ngIf="!(isLoading$ | async)">
        {{ 'LOGIN.RESET_PASSWORD.FORM.BUTTONS.SUBMIT' | translate }}
      </ng-container>
      <ion-spinner *ngIf="!!(isLoading$ | async)"></ion-spinner>
    </ion-button>
  </form>
</ion-content>
