import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TeamInfosEffectsBase} from "@team/pages/infos/store/infos.effects";
import {InfosService} from "@team/pages/infos/services/infos.service";
import {InfosActions} from "@team/pages/infos/store/infos.actions-type";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {InfoListDto, TenantInfoDto, TokenDto} from "@server-models";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {selectInfoPageList, selectInfoPagePagination} from "@team/pages/infos/store/infos.selectors";
import {ToastControllerService} from "@shared/services/toast-controller.service";
import {TranslateService} from "@ngx-translate/core";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {InfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";
import {InfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamInfosApiEffects extends TeamInfosEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _infosService: InfosService,
    private _toastControllerService: ToastControllerService,
    private _translationService: TranslateService
  ) {
    super(store, actions$);
  }

  /**
   * @name postMyRead
   * @memberof TeamInfosApiEffects
   * @description
   * Receive the action started on TeamInfosEffectsBase and use the latest state from infos.team
   * to use the userName for the toast
   * @param action
   */
  postMyRead(action: Observable<{ id: number }>): Observable<(OrgaResponse<InfosMyReadDto> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TeamLoginSelectors.selectToken))),
      withLatestFrom(this.store.pipe(select(selectInfoPageList))),
      mergeMap(([[currentId, appState], currentInfoList]) =>
        this._requestPostMyRead(currentId, this._getCurrentUserName(appState), currentInfoList)
      )
    );
  }

  /**
   * @name postPaginated
   * @memberof TeamInfosApiEffects
   * @description
   * Receive the action started on TeamInfosEffectsBase and use the latest state from infos.pagination
   * to check if there is more pages available and call a function to request infos data
   * @param action
   * @return {Observable<(OrgaResponse<InfoListDto[]> | any)>}
   */
  postPaginated(action: Observable<InfosRequestPagination>): Observable<(OrgaResponse<InfoListDto[]> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectInfoPagePagination))),
      mergeMap(([action, paginationState]) => {
          if (action.refresh) {
            return this._requestPostPaginated(action);
          } else if (this._checkPaginationState(action, paginationState)) {
            return this._requestPostPaginated(action);
          } else {
            return [InfosActions.postItemsPaginatedCancel()];
          }
        }
      )
    );
  }

  /**
   * @name _checkPaginationState
   * @memberof TeamInfosApiEffects
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @param action
   * @param state
   * @private
   * @return {boolean}
   */
  private _checkPaginationState(action: InfosRequestPagination, state: Pagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestPostPaginated
   * @memberof TeamInfosApiEffects
   * @description
   * Request the data to infoService and map the value to launch and pass throw the action need it
   * @param action
   * @private
   */
  private _requestPostPaginated(action: InfosRequestPagination): Observable<(OrgaResponse<InfoListDto[]> | any)> {
    return this._infosService.pagePostPaginated(action.params, action.body).pipe(
      map((data: OrgaResponse<InfoListDto[]>) =>
        action.refresh
          ? InfosActions.postItemsPaginatedRefresh({data})
          : InfosActions.postItemsPaginatedSuccess({data})
      ),
      catchError((error) => [InfosActions.postItemsPaginatedFail({error})])
    )
  }

  /**
   * @name _requestPostMyRead
   * @memberof TeamInfosApiEffects
   * @description
   * Request the data to infoService, call the toast service and launch the action need it
   * @param action
   * @param userName
   * @param currentInfoList
   * @private
   */
  private _requestPostMyRead(action: {
    id: number
  }, userName: string, currentInfoList: InfoListDto[]): Observable<(OrgaResponse<InfosMyReadDto> | any)> {
    return this._infosService.postMyReadById(action.id).pipe(
      map((data: OrgaResponse<InfosMyReadDto>) => {
        const updatedItems = this._updateReadAtItem(currentInfoList, action.id);
        this._toastControllerService.observableToast({message: this._formatMyReadToastMessage(userName)});
        return InfosActions.postItemsMyReadSuccess({updatedItems: updatedItems, data})
      }),
      catchError((error) => [InfosActions.postItemsMyReadFail({error})])
    )
  }

  /**
   * @name _getCurrentUserName
   * @memberof TeamInfosApiEffects
   * @description
   * return the current name of the user
   * @param appState
   * @private
   */
  private _getCurrentUserName(appState: TokenDto | null | undefined): string {
    const currentInfoTenants = appState?.info?.tenants as TenantInfoDto[];
    return currentInfoTenants[0].displayName as string;
  }

  /**
   * @name _formatMyReadToastMessage
   * @memberof TeamInfosApiEffects
   * @description
   * Format the message to send the toast
   * @param userName
   * @private
   */
  private _formatMyReadToastMessage(userName: string): string {
    const preSentence = this._translationService.instant("GENERAL.TOAST.INFOS.CARDS.READ.USER");
    const postSentence = this._translationService.instant("GENERAL.TOAST.INFOS.CARDS.READ.READY");
    return `${preSentence} ${userName}: ${postSentence}.`;
  }

  /**
   * @name _updateReadAtItem
   * @description
   * find the itemId in the existing items and set a temporal date in isRead
   * to be able to filter by isRead values since the request don't return anything more than just success
   * @memberof TeamInfosApiEffects
   * @param items
   * @param itemId
   * @private
   */
  private _updateReadAtItem(items: InfoListDto[], itemId: number): InfoListDto[] {
    const itemIndex = items.findIndex(item => item.infoId === itemId);

    if (itemIndex === -1) {
      return items;
    }

    const updatedItems = [...items];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      isRead: !updatedItems[itemIndex].isRead
    };
    return updatedItems;
  }
}
