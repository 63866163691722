import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {TeamIssuesApiService} from "@team/pages/issues/services/team-issues-api.service";
import {TeamIssuesActions} from "@team/pages/issues/store/team-issues.actions-type";
import {
  FileDto, FilterOperators,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  MessageIssueDto,
  MessageIssuePreviewDto,
  MissionIssueDto,
  MissionIssuePreviewDto, Orders, ResourceListDto,
  StereotypeDto,
  StereotypeListDto, SubmitMessageIssueDto, SubmitMissionIssueDto, SubmitTicketIssueDto,
  TicketIssueDto,
  TicketIssuePreviewDto
} from "@server-models";
import {Router} from "@angular/router";
import {TeamFileApiService} from "@team/services/team-file-api.service";
import {ImageService} from "@shared/services/image.service";
import {CameraService} from "@shared/services/camera.service";
import {ModalLoaderService} from "@shared/services/modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {IssuesRequestPagination} from "@shared/components/issues/interfaces/issues-request-pagination.interface";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {BaseIssuesApiEffects} from "@shared/components/issues/store/base-issues-api.effects";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {
  TemplatePreviewIssueType
} from "@shared/components/issues/store/base-issues.actions";
import {TeamIssuesService} from "@team/pages/issues/services/team-issues.service";
import {IssuesBaseFactory} from "@shared/components/issues/services/issues-base.factory";
import {TeamIssuesSelectors} from "@team/pages/issues/store/team-issues.selector-type";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class TeamIssuesApiEffects extends BaseIssuesApiEffects {

  constructor(
    actions$: Actions,
    store: Store,
    _cameraService: CameraService,
    _modalLoaderService: ModalLoaderService,
    _translateService: TranslateService,
    _imageService: ImageService,
    _issuesBaseService: IssuesBaseService,
    _issuesBaseFactory: IssuesBaseFactory,
    _router: Router,
    private _teamIssuesApiService: TeamIssuesApiService,
    private _teamIssuesService: TeamIssuesService,
    private _teamFileApiService: TeamFileApiService
  ) {
    super(actions$, store, _cameraService, _modalLoaderService, _translateService, _imageService, _issuesBaseService, _issuesBaseFactory, _router);
  }

  _requestGetPaginated(action: IssuesRequestPagination): Observable<(OrgaResponse<IssueListDto[]> | any)> {
    const body = [
      {
        property: "issueType",
        value: IssueType.Mission.toString(),
        operator: FilterOperators.NotEqual
      }
    ]
    const options = {
      params: {
        PageSize: action.params.pageSize.toString(),
        PageNumber: action.params.pageNumber.toString(),
        Cols: action.params.cols,
        SortField: "createdAt",
        Sort: Orders.Descending.toString()
      }
    }
    return this._teamIssuesApiService.postIssueRecentFilterList(body, options).pipe(
      map((data: OrgaResponse<IssueListDto[]>) =>
        action.refresh
          ? TeamIssuesActions.getItemsPaginatedRefresh({data})
          : TeamIssuesActions.getItemsPaginatedSuccess({data})
      ),
      catchError((error) => [TeamIssuesActions.getItemsPaginatedFail({error})])
    )
  }

  _requestGetTemplate(): Observable<(OrgaResponse<IssueTemplateListDto[]> | any)> {
    const body = [
      {
        property: "contentType",
        value: IssueContentType.Mission.toString(),
        operator: FilterOperators.NotEqual
      }
    ]
    return this._teamIssuesApiService.postIssueTemplateFilterList(body).pipe(
      map((data: OrgaResponse<IssueTemplateListDto[]>) =>
        TeamIssuesActions.getItemsTemplateSuccess({data})
      ),
      catchError((error) => [TeamIssuesActions.getItemsTemplateFail({error})])
    );
  }

  override navigateToNewIssue(action: Observable<any>) {
    return action.pipe(
      tap(() => {
        this._router.navigate([`team/logged/issues/new`])
      })
    )
  }

  override navigateToIssues(action: Observable<any>) {
    return action.pipe(
      tap(() => {
        this._router.navigate([`team/logged/issues`])
      })
    )
  }

  override navigateToTemplatePreview(action: Observable<{
    templateId: number,
    templateIssueType: IssueContentType
  }>): Observable<any> {
    return action.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/new/${action.templateId}`])
      })
    );
  }

  override formatReadDetailDataFinish(action: Observable<{
    detailDataFormatted: IssueFormattedDetailData
  }>): Observable<any> {
    return action.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/${action.detailDataFormatted.issueId}`])
      })
    )
  }

  _requestByIdByIssueType(action: { id: number, issueType: IssueType }): Observable<any> {
    const service = this._teamIssuesService.getApiServiceByIssueType(action.issueType);
    return service.getIssueById(action.id).pipe(
      map((data: MessageIssueDto | MissionIssueDto | TicketIssueDto) =>
        TeamIssuesActions.getByIdSuccess({data, cacheGuid: this._issuesBaseService.generateGuid()})
      ),
      catchError((error) => [TeamIssuesActions.getByIdFail({error})])
    )
  }

  _requestGetStereotypeById(action: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return this._teamIssuesApiService.getStereotypeById(action.stereotypeId).pipe(
      map((data: StereotypeDto) => {
          return TeamIssuesActions.getStereotypeByIdSuccess({stereotype: data, cacheGuid: action.cacheGuid})
        }
      ),
      catchError((error) => [TeamIssuesActions.getStereotypeByIdFail({error})])
    )
  }

  override _requestGetStereotypeByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto> | any> {
    return this._teamIssuesApiService.getStereotypeFilterByEntityType(entityType, cacheControl).pipe(
      map((data: OrgaResponse<StereotypeListDto>) => {
          return TeamIssuesActions.getStereotypeByEntityTypeSuccess({data})
        }
      ),
      catchError((error) => [TeamIssuesActions.getStereotypeByEntityTypeFail({error})])
    )
  }

  override sendTemplatePreviewSuccess(action: Observable<any>): Observable<any> {
    return action.pipe(
      exhaustMap((_) => {
        this._router.navigate([`team/logged/issues/success`]);
        return [TeamIssuesActions.thanksPageNavigation()]
      })
    );
  }

  override thanksPageNavigationBack(action: Observable<any>): Observable<any> {
    return action.pipe(
      exhaustMap((_) => {
        this._router.navigate([`team/logged/issues`])
        return [BaseIssuesActions.thanksPageNavigationBackDone()]
      })
    );
  }

  _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string): Observable<{
    signature: FileDto
  } | any> {
    return this._teamFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [TeamIssuesActions.saveSignatureSuccess({signatureId: data.fileId!})]
        }
      ),
      catchError((error) => [TeamIssuesActions.saveSignatureFail({error})])
    )
  }

  _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any> {
    return this._teamFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [TeamIssuesActions.savePictureSuccess({fileId: data.fileId!, path: data.path!, pictureId})]
        }
      ),
      catchError((error) => [TeamIssuesActions.savePictureFail({error})])
    )
  }

  override _requestGetTemplatePreviewByIssueType(action: TemplatePreviewIssueType): Observable<(unknown | any)> {
    const apiService = this._teamIssuesService.getApiServiceByIssueContentType(action.issueType);
    return apiService.getIssueTemplatePreviewListById(action.id).pipe(
      map((data: MessageIssuePreviewDto | MissionIssuePreviewDto | TicketIssuePreviewDto) =>
        TeamIssuesActions.getItemsTemplatePreviewSuccess({data})
      ),
      catchError((error) => [TeamIssuesActions.getItemsTemplatePreviewFail({error})])
    )
  }

  override requestItemsResource(action: any): Observable<any> {
    return this._teamIssuesApiService.requestResourceListByFilter([{}]).pipe(
      map((data: OrgaResponse<ResourceListDto>) =>
        TeamIssuesActions.getItemsResourceSuccess({data})
      ),
      catchError((error) => [TeamIssuesActions.getItemsResourceFail({error})])
    )
  }

  override prepareFormsToSendTemplatePreview(action: Observable<{ form: FormGroup, templatePreviewDto: MessageIssuePreviewDto | MissionIssuePreviewDto | TicketIssuePreviewDto }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe((select(TeamIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{form, templatePreviewDto}, templateIssueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto);
        return [TeamIssuesActions.prepareFormsToSendTemplatePreviewDone({submitReady})]
      })
    );
  }

  override sendTemplatePreview(action: Observable<{
    issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto
  }>): Observable<void> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TeamIssuesSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(TeamIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{issuePrepared}, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issuePrepared,
          issueContentType
        });
      })
    );
  }

  override _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto
    issueContentType: IssueContentType
  }): Observable<(unknown | any)> {
    const apiService = this._teamIssuesService.getApiServiceByIssueContentType(action.issueContentType);
    return apiService.sendIssueTemplatePreviewById(action.templateId, action.issuePrepared).pipe(
      map(() => TeamIssuesActions.sendTemplatePreviewSuccess({})),
      catchError(error => [TeamIssuesActions.sendTemplatePreviewFail({error})])
    );
  }


  navigateToNewSpecificTemplate(action: Observable<{ templateId: number }>): Observable<any> {
    return action.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/new/${action.templateId}`])
      })
    )
  }
}
