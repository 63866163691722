import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {TeamIssuesMessageApiService} from "@team/pages/issues/services/team-issues-message-api.service";
import {TeamIssuesMissionApiService} from "@team/pages/issues/services/team-issues-mission-api.service";
import {TeamIssuesTicketApiService} from "@team/pages/issues/services/team-issues-ticket-api.service";
import {
  TeamIssuesWinterhalterServiceCallApiService
} from "@team/pages/issues/services/winterhalter/service-call-api.service";
import {IssueBaseServiceInterface} from "@shared/components/issues/interfaces/issue-base-service.interface";

@Injectable({
  providedIn: 'root',
})
export class TeamIssuesService {
  constructor(
    private _teamIssuesMessageApiService: TeamIssuesMessageApiService,
    private _teamIssuesMissionApiService: TeamIssuesMissionApiService,
    private _teamIssuesTicketApiService: TeamIssuesTicketApiService,
    private _teamIssuesWinterhalterServiceCallApiService: TeamIssuesWinterhalterServiceCallApiService
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): IssueBaseServiceInterface {
    switch (issueType) {
      case IssueType.Message:
        return this._teamIssuesMessageApiService;
      case IssueType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueType.Ticket:
        return this._teamIssuesTicketApiService;
      case IssueType.WinterhalterServiceCall:
        return this._teamIssuesWinterhalterServiceCallApiService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${issueType}`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): IssueBaseServiceInterface {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._teamIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._teamIssuesTicketApiService;
      case IssueContentType.WinterhalterServiceCall:
        return this._teamIssuesWinterhalterServiceCallApiService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${issueContentType}`);
    }
  }


}
