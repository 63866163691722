import {Injectable} from "@angular/core";
import {TeamDocumentsEffectsBase} from "@team/pages/documents/store/documents.effects";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {DocumentsApiService} from "@team/pages/documents/services/documents-api.service";
import {mergeMap, Observable, throwError, withLatestFrom} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {catchError, map} from "rxjs/operators";
import {DocumentsActions} from "@team/pages/documents/store/documents.actions-type";
import {DocumentsRequestPagination} from "@team/pages/documents/interfaces/documents-request-pagination.interface";
import {selectDocumentsPagePagination} from "@team/pages/documents/store/documents.selectors";

@Injectable({
  providedIn: 'root'
})
export class TeamDocumentsApiEffects extends TeamDocumentsEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _documentsApiService: DocumentsApiService
  ) {
    super(store, actions$);
  }

  /**
   * @name getPagination
   * @description
   * handle the action to forward to need it request
   * @memberof TeamDocumentsApiEffects
   * @param action
   */
  getPagination(action: Observable<DocumentsRequestPagination>): Observable<OrgaResponse<OrgaFolderItemDto[]> | any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectDocumentsPagePagination))),
      mergeMap(([action, paginationState]) => {
        if (action.refresh) {
          return this._requestGetPagination(action)
        } else if (this._checkPaginationState(action, paginationState)) {
          return this._requestGetPagination(action)
        } else {
          return [DocumentsActions.getItemsPaginatedCancel()];
        }
      }
      )
    );
  }


  getItemsSearched(action: Observable<{ toSearch: string, path: string }>): Observable<OrgaResponse<OrgaFolderItemDto[]> | any> {
    return action.pipe(
      mergeMap((action) => {
        return this._requestItemsSearched(action)
      })
    )
  }

  /**
   * @name _requestItemsSearched
   * @description
   * request the data to the document service to search by text
   * @memberof TeamDocumentsApiEffects
   * @param action
   * @private
   */
  private _requestItemsSearched(action: { toSearch: string, path: string }): Observable<OrgaResponse<OrgaFolderItemDto[]> | any> {
    return this._documentsApiService.getDocumentsBySearch(action.toSearch!, action.path).pipe(
      mergeMap((data: OrgaResponse<OrgaFolderItemDto[]>) => {
        return [DocumentsActions.getItemsSearchedSuccess({data})]
      }),
      catchError((error) => {
        return [DocumentsActions.getItemsSearchedFail({error})]
      })
    )
  }


  /**
   * @name _checkPaginationState
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @memberof TeamDocumentsApiEffects
   * @param action
   * @param state
   * @private
   */
  private _checkPaginationState(action: DocumentsRequestPagination, state: Pagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestGetPagination
   * @description
   * request the data to the document service and redirect to need it action
   * @memberof TeamDocumentsApiEffects
   * @param action
   * @private
   */
  private _requestGetPagination(action: DocumentsRequestPagination): Observable<OrgaResponse<OrgaFolderItemDto[]> | any> {
    return this._documentsApiService.getDocumentsItems(action.tenantId!, action.path, action.params).pipe(
      map((data: OrgaResponse<OrgaFolderItemDto[]>) => {
        return action.refresh
          ? DocumentsActions.getItemsPaginatedRefresh({data})
          : DocumentsActions.getItemsPaginatedSuccess({data});
      }),
      catchError((error) => {
        return throwError(() => DocumentsActions.getItemsPaginatedFail({error}));
      })
    );
  }

}
