<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-buttons>
      <ion-button *ngIf="innerDoc" (click)="back()">
        <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
      </ion-button>
      <ng-container *ngIf="!innerDoc">
        <app-button-team-side-menu [showMenu]="!innerDoc"
                                   [title]="'TEAM.DOCUMENTS.PAGE_NAME' | translate"></app-button-team-side-menu>
      </ng-container>
      <ion-title [innerText]="'/' + getLastPosition(currentPath)"></ion-title>
      <ion-buttons>
        <ion-button (click)="changeDisplayBar()">
          <ion-icon name="search"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>
  <ion-card *ngIf="displaySearchBar" class="item-searchbar">
    <app-base-search-bar (valueToSearch)="onSearch($event)"></app-base-search-bar>
  </ion-card>
  <ion-spinner *ngIf="!ownLoad && (isLoading$ | async) && !infinityLoading"></ion-spinner>
  <ng-container *ngIf="!(!ownLoad && (isLoading$ | async) && !infinityLoading)">
    <app-documents-list (pathChange)="onPathChange($event)"
                        [documentList]="documentsData$ | async"></app-documents-list>
  </ng-container>
  <ion-infinite-scroll
    threshold="0px"
    (ionInfinite)="loadMoreDocuments($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
