import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Router, UrlTree} from "@angular/router";
import {Observable, withLatestFrom} from "rxjs";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TeamWelcomeGuard {

  constructor(private _store: Store, private _router: Router) {
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    return this._store.pipe(

      withLatestFrom(this._store.select(TeamLoginSelectors.selectLogin)),
      map(([_, loginState]) => {
        if(loginState.app?.info?.isGuest) {
          return true;
        } else if (loginState.app?.token) {
          return true;
        } else {
          return this._router.createUrlTree(['team/login']);
        }
      })
    );
  }
}
