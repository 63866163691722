import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {filter, take, withLatestFrom} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectArticlesPathStart, selectKnowledgePathLoading} from "@team/pages/knowledge/store/knowledge.selectors";
import {getPathItemsByIdStart} from "@team/pages/knowledge/store/knowledge.actions";
import {KnowledgeArticleStartDto} from "@server-models";
import {map} from "rxjs/operators";
import {KnowledgeState} from "@team/pages/knowledge/store/knowledge.state";

export const knowledgeDetailResolver: ResolveFn<KnowledgeArticleStartDto[] | null | undefined> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store: Store<KnowledgeState> = inject(Store);
    const currentId: string = route.paramMap.get('id')!;
    store.dispatch((getPathItemsByIdStart({
      id: currentId,
      refresh: false
    })));

    return store.pipe(
      select(selectArticlesPathStart),
      withLatestFrom(store.pipe(select(selectKnowledgePathLoading))),
      filter(([data, loading]) => !!data && !loading),
      take(1),
      map(([data]) => data)
    );
  };
