import {Injectable} from "@angular/core";
import {ModalControllerService} from "@shared/services/modal-controller.service";

import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {
  NewPasswordDialogComponent
} from "@team/pages/login/components/new-password-dialog/new-password-dialog.component";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {Router} from "@angular/router";
import {AlertBaseControllerService} from "@shared/services/alert-controller.service";
import {MeDto} from "@server-models";
import {ViewerTenantsComponent} from "@team/pages/login/components/viewer-tenants/viewer-tenants.component";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LoginBaseStorageService} from "@shared/stores/login-base/services/login-base-storage.service";
import {
  RequestRegisterUserDialogComponent
} from "@team/pages/login/components/request-register-user-dialog/request-register-user-dialog.component";
import {
  PinRegisterUserDialogComponent
} from "@team/pages/login/components/pin-register-user-dialog/pin-register-user-dialog.component";
import {
  InvitationCodeDialogComponent
} from "@team/pages/login/components/invitation-code-dialog/invitation-code-dialog.component";
import {
  InvitationTeamCodeDialogComponent
} from "@team/pages/login/components/invitation-team-code-dialog/invitation-team-code-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class TeamLoginSideEffectsService {
  constructor(
    private _store: Store<TeamLoginState>,
    private _loginBaseStorageService: LoginBaseStorageService,
    private _alertControllerService: AlertBaseControllerService,
    private _modalControllerService: ModalControllerService,
    private _translationService: TranslateService,
    private _router: Router
  ) {
  }

  /**
   * @name multipleTenants
   * @description
   * checks if there are multiple tenants
   * @memberof TeamLoginSideEffectsService
   * @param tokenInfo
   * @returns {boolean}
   */
  multipleTenants(tokenInfo: MeDto): boolean {
    const tenants = tokenInfo?.tenants;
    return tenants?.length! > 1;
  }

  /**
   * @name getTenants
   * @description
   * display modal if not tenantId existing else dispatch already existing tenant
   * @memberof TeamLoginSideEffectsService
   * @param tokenInfo
   */
  getTenants(tokenInfo: MeDto): void {
    this._checkExistingTenants().subscribe((tenantId) => {

      if (!(!!tenantId)) {
        this._modalControllerService.showModal(ViewerTenantsComponent, '', {
          data: tokenInfo.tenants
        });
      } else {
        this._store.dispatch(TeamLoginActions.existingMultiTenant({tenantId: tenantId!}))
      }
    });
  }

  /**
   * @name _checkExistingTenants
   * @description
   * returns tenantId if exists
   * @memberof TeamLoginSideEffectsService
   * @private
   * @returns {Observable<number | undefined>}
   */
  private _checkExistingTenants(): Observable<number | undefined> {
    return this._loginBaseStorageService.getSt().pipe(
      map((value) => value?.currentTenant?.tenantId)
    );
  }

  askRegistrationData(currentEmail: string) {
    this._modalControllerService.showModal(RequestRegisterUserDialogComponent, '', {
      currentEmail
    });
  }

  askRegistrationPinGuest(passwordBase64: string, email: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(PinRegisterUserDialogComponent, '', {
      passwordBase64,
      email
    });
  }

  askForTeamCode() {
    this._modalControllerService.showModal(InvitationTeamCodeDialogComponent, '', {});
  }

  askForInvitationCode(code: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(InvitationCodeDialogComponent, '', {
      code
    });
  }

  invitationCodeRevoked() {
    this._alertControllerService.observableAlert({
      header: this._translationService.instant('TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.HEADER'),
      subHeader: this._translationService.instant('TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.SUBHEADER'),
      message: this._translationService.instant('TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.MESSAGE'),
      buttons: [
        {
          text: this._translationService.instant('TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.BUTTONS.OK'),
        }
      ]
    })
  }

  /**
   * @name newPasswordDialog
   * @description
   * display a modal of new password
   * @memberof TeamLoginSideEffectsService
   * @param token
   * @param email
   */
  newPasswordDialog(token: string, email: string): void {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(NewPasswordDialogComponent, '', {
      newPasswordData: {
        token,
        email
      }
    });
  }

  /**
   * @name launchAppByPassword
   * @description
   * dispatch an action login by password
   * @memberof TeamLoginSideEffectsService
   * @param email
   * @param password
   */
  launchAppByPassword(email: string, password: string): void {
    this._store.dispatch(TeamLoginActions.byPassword({login: email, password: password}));
  }

  /**
   * @name resetRequestPasswordAlert
   * @description
   * display an alert to info the user that the email have sent successfully
   * @memberof TeamLoginSideEffectsService
   */
  resetRequestPasswordAlert(): void {
    this._alertControllerService.observableAlert({
      header: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.HEADER'),
      subHeader: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.SUB_HEADER'),
      message: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.MESSAGE'),
      buttons: [
        {
          text: this._translationService.instant('LOGIN.RESET_PASSWORD.ALERT.BUTTONS.ACCEPT'),
          handler: () => {
            this._router.navigateByUrl("team/login");
          }
        }
      ],
    })
  }
}
