import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FilterDto, InfoListDto} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {map} from "rxjs/operators";
import {TeamHttpClientBaseApiService} from "@team/services/http-client-base-api/team-http-client-base-api.service";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {InfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";

@Injectable({
  providedIn: 'root',
})
export class InfosService extends TeamHttpClientBaseApiService {

  /**
   * @name pagePostPaginated
   * @memberof InfosService
   * @description
   * Make a post request to Orga server to retrieve the infos data parameterized and with body
   * @param paramsPaginated
   * @param body
   */
  pagePostPaginated(paramsPaginated: PaginationParams, body: FilterDto[]): Observable<OrgaResponse<InfoListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SotField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<OrgaResponse<InfoListDto[]>>(`/orga/v2/info/filter`,
      undefined, body, options).pipe(
      map((res: OrgaResponse<InfoListDto[]>) => res)
    );
  }

  /**
   * @name postMyReadById
   * @memberof InfosService
   * @description
   * Make a post request to Orga server to mark an info as read by id
   * @param id
   */
  postMyReadById(id: number): Observable<OrgaResponse<InfosMyReadDto>> {
    return super.post<OrgaResponse<InfosMyReadDto>>(`/orga/v2/me/info/${id}/read`,
      undefined, undefined, undefined);
  }
}
