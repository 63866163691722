<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <app-button-team-side-menu [title]="'TEAM.KNOWLEDGE.PAGE_NAME' | translate">
      <ion-button (click)="changeDisplayBar()">
        <ion-icon name="search"></ion-icon>
      </ion-button>
    </app-button-team-side-menu>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>
  <ion-card *ngIf="displaySearchBar" class="item-searchbar">
    <app-base-search-bar (valueToSearch)="onSearch($event)"></app-base-search-bar>
  </ion-card>
  <ion-spinner *ngIf="!ownLoad && (knowledgePathIsLoading$ | async) && !infinityLoading"></ion-spinner>
  <ng-container *ngIf="!(!ownLoad && (knowledgePathIsLoading$ | async) && !infinityLoading)">
    <ng-container *ngIf="(knowledgePathData$ | async)?.length! > 0; else noKnowledge">
      <app-knowledge-card [knowledgeList]="knowledgePathData$ | async"></app-knowledge-card>
    </ng-container>
  </ng-container>
  <ion-infinite-scroll
    (ionInfinite)="loadMoreKnowledgePath($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ng-template #noKnowledge>
  <ion-card class="card-container">
    <ion-item>
      <ion-label class="ion-text-center" [innerText]="'TEAM.KNOWLEDGE.EMPTY' | translate"></ion-label>
    </ion-item>
  </ion-card>
</ng-template>
