import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {select, Store} from "@ngrx/store";
import {IonicModule} from "@ionic/angular";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SideBarActions} from "@team/components/side-bar/store/side-bar.actions-type";
import {selectSideBarLogo} from "@team/components/side-bar/store/side-bar.selectors";
import {SideBarState} from "@team/components/side-bar/store/side-bar.state";
import {TranslateModule} from "@ngx-translate/core";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {selectCurrentLogin, selectTenantDisplayName} from "@shared/stores/login-base/store/login-base.selector";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    SafePipe,
    NgOptimizedImage,
    TranslateModule
  ],
  standalone: true
})
export class SideBarComponent  implements OnInit {

  logo$: Observable<string>;
  displayName$: Observable<string>;
  deleteEnabled : Observable<boolean> = of(false);

  constructor(
    private _store: Store<SideBarState>,
    private _loginStore: Store<TeamLoginState>
  ) {
    this.logo$ = of();
    this.displayName$ = of();

    // simulated for app tests by store providers
    this.deleteEnabled = this._loginStore.pipe(
      select(selectTenantDisplayName),
      map(x => x === "App Test")
    );
  }

  ngOnInit() {
    this._initValues();
  }

  /**
   * @name loadLogo
   * @description
   * dispatch the action to request the logo
   * @memberof SideBarComponent
   */
  loadLogo() {
    this._store.dispatch(SideBarActions.getLogo());
  }

  /**
   * @name _initValues
   * @description
   * init the values to let the template handle it with async pipe
   * @memberof SideBarComponent
   */
  private _initValues(): void {
    this.logo$ = this._store.pipe(select(selectSideBarLogo));
    this.displayName$ = this._loginStore.pipe(select(TeamLoginSelectors.selectTenantDisplayName));
  }

  /**
   * @name logout
   * @description
   * launch a logout action
   * @memberof SideBarComponent
   */
  logout() {
    this._loginStore.dispatch((TeamLoginActions.initLogout()));
  }

  delete() {
    if(confirm("Delete account and all data?")) {
      alert("Account will be deleted within 7 days");
      this._loginStore.dispatch((TeamLoginActions.initLogout()));
    }
  }
}
